import React, { useEffect, useState, Component } from "react";
import { Model } from "survey-core";
import { Survey, ReactElementFactory } from "survey-react-ui";
import "survey-core/defaultV2.min.css";
import "./index.css";
import { modelJson } from "./json";

//const API_URL = process.env.REACT_APP_API_URL;
const API_URL = "https://ghi-report-api.mystrand.org/api";
// const API_URL = "http://localhost:8000/api";

async function fetchoptions(params) {
  try {
    const response = await fetch(`${API_URL}/v2/zoho/risk_calculator/diseases`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const jsonData = await response.json(); // Assuming the response is in JSON format
    return jsonData;
  } catch (error) {
    console.error('Error fetching data:', error);
    return null; // Handle the error case
  }

}

async function initializeModel() {
  const json = await fetchoptions();
  if (json) {
    const matrixColumns = json;
    matrixColumns.reverse();

    const matrixElement = modelJson.pages
      .find(page => page.name === "personal_health_info_page")
      .elements.find(panel => panel.name === "personal_health_info_panel")
      .elements.find(el => el.type === "matrixdynamic" && el.name === "personal_health_history");

    if (matrixElement) {
      // Find the disease column to update its choices
      const diseaseColumn = matrixElement.columns.find(col => col.name === "personal_disease");
      if (diseaseColumn) {
        diseaseColumn.choices = matrixColumns.map(item => ({
          value: item.ID,
          text: item.Disorder
        }));
      }
    }
    const panelDynamicElement = modelJson.pages
      .find(page => page.name === "family_relations_page")
      .elements.find(el => el.type === "paneldynamic" && el.name === "relationsinfo")
      .templateElements.find(el => el.type === "matrixdynamic" && el.name === "health_history")

    if (panelDynamicElement) {
      // Find the disease column to update its choices
      const diseaseColumn = panelDynamicElement.columns.find(col => col.name === "disease");
      if (diseaseColumn) {
        diseaseColumn.choices = matrixColumns.map(item => ({
          value: item.ID,
          text: item.Disorder
        }));
      }
    }
    // console.log(modelJson);
    return modelJson;

  } else {
    console.error('Failed to fetch data for model initialization');
    return modelJson;
  }
}

class ItemTemplateComponent extends Component {
  severityMap = {
    "Mild": "Suggestive symptoms but no clinical diagnosis; no adjustments required for day-to-day lifestyle activities",
    "Moderate": "Clinically suspected condition; minor adjustments required in day-to-day lifestyle activities",
    "Severe": "Clinically diagnosed condition; significant adjustments required in day-to-day lifestyle activities"
  };

  render() {
    const { item } = this.props;
    return (
      <div className="my-list-item" style={{ display: "flex", flexDirection: "column" }}>
        <span className="list-item_text">{item.text}</span>
        <span className="list-item_subtext">{this.severityMap[item.value]}</span>
      </div>
    );
  }
}

ReactElementFactory.Instance.registerElement(
  "severity-item",
  (props) => {
    return React.createElement(ItemTemplateComponent, props);
  }
);


function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function SurveyComponent() {
  const [survey, setSurvey] = React.useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [popupData, setPopupData] = useState(null);
  const [popupLevel, setPopupLevel] = useState('low');
  const [isFirstPage, setIsFirstPage] = useState(true);

  React.useEffect(() => {
    const initializeSurvey = async () => {
      const updatedModelJson = await initializeModel();

      if (updatedModelJson) {
        const surveyInstance = new Model(updatedModelJson);
        surveyInstance.onCurrentPageChanged.add((sender) => {
          setIsFirstPage(sender.currentPageNo === 0);
        });
        surveyInstance.completedHtml = "<h2>Thank you for completing the survey!</h2><h5>Your results will be displayed shortly...Do not close!</h5>";
        // surveyInstance.onAfterRenderPage.add((survey, options) => {
        //   const imageContainer = document.createElement("div");
        //   imageContainer.innerHTML = `<div style='position: absolute; top: 0; right: 0;'>
        //                                 <img src='https://strandls.com/static/8f55cc866f7e3a7b1d2e5f49475e09a5/3bfd9/Mask-Group-77.webp' 
        //                                 alt='Health Information' style='max-width: 150px; height: auto;'/>
        //                               </div>`;
        //   options.htmlElement.appendChild(imageContainer);
        // });
        // Adding onComplete handler
        surveyInstance.onComplete.add((sender, options) => {
          const updatedInfos = JSON.stringify(sender.data, null, 3);
          fetch(`${API_URL}/v2/zoho/risk_calculator`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: updatedInfos,
          })
            .then(response => response.json())
            .then(data => {
              // console.log('Successfully sent updated infos to backend:', data);
              setPopupData(data.riskLevel); // Set the data for the popup
              const level = determineLevel(data); // A function to decide 'high', 'medium', or 'low'
              surveyInstance.completedHtml = `
              <div style='text-align: left; padding-left: 24px; padding-right: 24px;'>
                <h2>Thank you for completing the survey!</h2>
                <h3>Based on your responses, your assessed overall risk level is: <span class='level-text ${level}' style="font-size: inherit;">${level}</span></h3>
                <hr style="margin: 16px 0;"/>
                <h6 style="margin-bottom: 0;">Genetic Risk Breakdown</h6>
                <ul style="padding-left: 42; font-size: 1.2em; text-align:left">
                  <li><strong>Based on your health history:</strong> Probability of ${capitalizeFirstLetter(data.selfRiskLevel)} risk</li>
                  <li><strong>Based on your family health history:</strong> Probability of ${capitalizeFirstLetter(data.relativeRiskLevel)} risk </li>
                  <li><strong>Based on your fertility and reproductive health history:</strong> Probability of ${capitalizeFirstLetter(data.reproductionRiskLevel)} risk</li>
                </ul>
              </div>
              `;

              if (level === 'High') {
                surveyInstance.completedHtml += "<div style='text-align: left; padding: 24px;'><p>Based on the information provided by you, you are strongly recommended to have a certified <b><u>Genetic Counselor review</u></b> your individual health risk and family history. The rationale behind your risk call-out will be explained to you and a clear path for next steps will be delineated. In our internal research studies a small percentage of individuals had their risk levels downgraded to medium risk (17%) and low risk (1.5%) after the GC session.</p><p><a href=\"https://strandls.com/genetic-counseling/\">Schedule your genetic counseling session</a> Please feel free to discuss your medical history and any concerns you may have.</p></div>";
              } else if (level === 'Medium') {
                surveyInstance.completedHtml += "<div style='text-align: left; padding: 24px;'><p>Based on the information provided by you, you will benefit from a <b><u>Genetic Counseling review</u></b> session to explain what a \"Medium Risk\" entails and what steps you may take to lead a healthy life. In our internal research studies a small percentage of individuals had their risk levels downgraded to \"Low Risk\" (15%) AFTER the Genetic Counseling session.</p><p><a href=\"https://strandls.com/genetic-counseling/\">Schedule your genetic counseling session </a> This session will help you make informed decisions about your health.</p></div>";
              } else if (level === 'Low') {
                surveyInstance.completedHtml += "<div style='text-align: left; padding: 24px;'><p>Based on the information provided by you, there appears to be a very low likelihood for you to benefit greatly from a <b><u>Genetic Counseling session</u></b>. It is always preferred to consult with a Genetic Counsellor to understand how these risk call-outs are associated with a healthy lifestyle. In our internal research studies (Sample size 147 individuals) there was a 100% concordance with this calculators risk call-out and that of the Genetic Counseling session.</p><p><a href=\"https://strandls.com/genetic-counseling/\">Schedule your genetic counseling session</a> This session will help you understand your genetic health and consider any preventive measures.</p></div>";
              }

              window.scrollTo({ top: 0, behavior: 'auto' });

              // console.log(popupLevel);
              // setShowPopup(true);
            })
            .catch(error => {
              console.error('Error sending updated infos to backend:', error);
            });

          // console.log(updatedInfos);
        });

        setSurvey(surveyInstance); // Update state to trigger re-render
      }
    };

    initializeSurvey();
  }, []);

  const determineLevel = (data) => {
    if (data.riskLevel === 'high') {
      return 'High';
    } else if (data.riskLevel === 'medium') {
      return 'Medium';
    } else {
      return 'Low';
    }
  };

  // Only render the Survey component if the survey instance is ready
  return (
    <div>
      {/* Survey rendering logic here */}

      {survey ? <Survey model={survey} /> : <p>Loading survey...</p>}
      {isFirstPage && <div className="disclaimer"><b>Disclaimer: </b>This online Risk Calculator was built in conjunction with the decision making matrix of a genetic counseling session. It is not a substitute for an informed Genetic Counseling session but instead an approximate guidance for the benefit a genetic counseling session may have. The quality and accuracy of the output is directly correlated with the accuracy of the input data. Errors and approximations of input data will result in erroneous reporting.</div>}
    </div>
  );
  // return survey ? <Survey model={survey} /> : <p>Loading survey...</p>;
}
export default SurveyComponent;
