import React from 'react';
import SurveyComponent from './SurveyComponent';
import './SurveyWrapper.css';

const SurveyWrapper = () => (
  <div className="wrapper-container">
    <div className="header">
      <a href='https://strandls.com'>
        <img src="https://strandls.com/static/8f55cc866f7e3a7b1d2e5f49475e09a5/3bfd9/Mask-Group-77.webp" alt="Logo" />
      </a>
      <h1 className="title">Genetic <span>Disease Risk Calculator</span></h1>
    </div>
    <div className="survey-component rounded-border">
      <SurveyComponent />
    </div>
  </div>
);

export default SurveyWrapper;
